import React,{ Suspense } from "react";
import Loader from "../common/loader/loader";

    const AdminDashboardpage = React.lazy(() => import('../Admin/pages/AdminDashboardpage'));
    const JobPost = React.lazy(() => import('../Admin/pages/Job_post/JobPost'));
    const Advertisement = React.lazy(() => import('../Admin/pages/advertisement/Advertisement'));
    const CourseBatch = React.lazy(() => import('../Admin/pages/courseBatch/CourseBatch'));
    const AddQuestions = React.lazy(() => import('../Admin/pages/masters/Add_Questions/AddQuestions'));
    const AdminReport = React.lazy(() => import('../Admin/pages/masters/AdminReport/AdminReport'));
    const Certificates = React.lazy(() => import('../Admin/pages/masters/Cerificates/Certificates'));
    const Locality = React.lazy(() => import('../Admin/pages/masters/Country_state_city/locality'));
    const CourseSubject = React.lazy(() => import('../Admin/pages/masters/Course_Subjects/CourseSubject'));
    const CreateCertificate = React.lazy(() => import('../Admin/pages/masters/Create_Certificate/CreateCertificate'));
    const JobRank = React.lazy(() => import('../Admin/pages/masters/Job_Rank/JobRank'));
    const JobCategory = React.lazy(() => import('../Admin/pages/masters/Job_category/JobCategory'));
    const NewsAndAnnouncement = React.lazy(() => import('../Admin/pages/masters/News_Announcement/NewsAndAnnouncement'));
    const OtherQuestions = React.lazy(() => import('../Admin/pages/masters/Other_questions/OtherQuestions'));
    const Shiptype = React.lazy(() => import('../Admin/pages/masters/Ship_Type/ShipType'));
    const UploadContent = React.lazy(() => import('../Admin/pages/masters/Upload_content/UploadContent'));
    const LearningCourses = React.lazy(() => import('../Admin/pages/masters/learningCourses/LearningCourses'));
    const SubLearningCourses = React.lazy(() => import('../Admin/pages/masters/subCourses/SubLearningCourses'));

    // Onboarding Pages
    const InstituteOnBoarding = React.lazy(() => import('../Admin/pages/onboarding/InstituteOnboarding/InstituteOnBoarding'));
    const InstituteOnboardingForm = React.lazy(() => import('../Admin/pages/onboarding/InstituteOnboarding/InstituteOnboardingform'));
    const Candidates = React.lazy(() => import('../Admin/pages/onboarding/candidates/Candidates'));
    const CompanyOnBoarding = React.lazy(() => import('../Admin/pages/onboarding/companyOnboarding/companyOnboarding'));
    const CompanyOnboardingForm = React.lazy(() => import('../Admin/pages/onboarding/companyOnboarding/companyOnboardingForm'));

    // Institute Pages
    const AvailableCourses = React.lazy(() => import('../Institute/pages/AvailableCourses'));
    const CourseRequest = React.lazy(() => import('../Institute/pages/CourseRequest/CourseRequest'));
    const InstituteDashboard = React.lazy(() => import('../Institute/pages/Dashboard/InstituteDashboard'));
    const InstituteDetails = React.lazy(() => import('../Institute/pages/InstituteProfile/InstituteDetails'));
    const Reports = React.lazy(() => import('../Institute/pages/Reports/Reports'));
    const ScheduleCourse = React.lazy(() => import('../Institute/pages/ScheduleCourse/ScheduleCourse'));

    // Common Pages
    const PageNotFound = React.lazy(() => import('../common/PageNotFound/PageNotFound'));
    const Sidebar = React.lazy(() => import('../common/sidebarComponents/Sidebar'));
    const WelcomePage = React.lazy(() => import('../common/welcomePage/welcomePage'));
    const ResetPassword = React.lazy(() => import('../commonAuth/forgotPassword/ResetPassword'));
    const ForgetPasswordEmail = React.lazy(() => import('../commonAuth/forgotPassword/forgetPasswordEmail'));
    const Login = React.lazy(() => import('../commonAuth/login/Login'));
    const CompanyRegister = React.lazy(() => import('../commonAuth/register/Company Register/CompanyRegister'));
    const InstituteRegister = React.lazy(() => import('../commonAuth/register/Institute Register/InstituteRegister'));
    const CompanyDepartment = React.lazy(()=> import ("../Admin/pages/masters/Add_Department/CompanyDepartment"))

    // Company Pages
    const CompanyDashBoard = React.lazy(() => import('../company/pages/CompanyDashboard/CompanyDashBoard'));
    const CompanyDetails = React.lazy(() => import('../company/pages/CompanyDetails/CompanyDetails'));
    const JobpostCompany = React.lazy(() => import('../company/pages/companyJobpost/JobpostCompany'));
    const CompanyReport = React.lazy(() => import('../company/pages/companyReport/CompanyReport'));
    const CandidateResume = React.lazy(() => import('../company/pages/companyResume/CandidateResume'));
    const CompanyOtherInfo = React.lazy(() => import('../company/pages/otherInfo/OtherInfo'));

    // Protected Route
    const ProtectRoute = React.lazy(() => import('./ProtectRoute'));

const { BrowserRouter, Routes, Route } = require("react-router-dom");

const AppRoutes = () => {
    return (
        <BrowserRouter>
        <Suspense fallback={<Loader/>}>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path='/register/company' element={<CompanyRegister />} />
                <Route path='/register/institute' element={<InstituteRegister />} />
                <Route path="/forgotPassword" element={<ForgetPasswordEmail />} />
                <Route path="/resetPassword" element={<ResetPassword />} />
                {/* All Routes with login Protection */}
                <Route path="/Admin" element={
                    <ProtectRoute>
                        <Sidebar>
                            <AdminDashboardpage />
                        </Sidebar>
                    </ProtectRoute>
                } />
                <Route path="/onboarding-institute" element={
                    <ProtectRoute>
                        <Sidebar>
                            <InstituteOnBoarding />
                        </Sidebar>
                    </ProtectRoute>


                } />
                <Route path="/onboarding-institute/form" element={
                    <ProtectRoute>
                        <Sidebar>
                            <InstituteOnboardingForm />
                        </Sidebar>
                    </ProtectRoute>


                } />

                <Route path="/onboarding-company" element={
                    <ProtectRoute>
                        <Sidebar>
                            < CompanyOnBoarding />
                        </Sidebar>
                    </ProtectRoute>

                } />

                <Route path="/onboarding-company/form" element={
                    <ProtectRoute>
                        <Sidebar>
                            < CompanyOnboardingForm />
                        </Sidebar>
                    </ProtectRoute>

                } />

                <Route path="/" element={

                    < WelcomePage />

                } />

                <Route path="/onboarding-candidates" element={
                    <ProtectRoute>
                        <Sidebar>
                            <Candidates />
                        </Sidebar>
                    </ProtectRoute>

                } />

                <Route path="/master-locality" element={
                    <ProtectRoute>
                        <Sidebar>
                            <Locality />
                        </Sidebar>
                    </ProtectRoute>

                } />

                <Route path="/master-courses" element={
                    <ProtectRoute>
                        <Sidebar>
                            <LearningCourses />
                        </Sidebar>
                    </ProtectRoute>
                } />

                <Route path="/master-subcourses" element={
                    <ProtectRoute>
                        <Sidebar>
                            <SubLearningCourses />
                        </Sidebar>
                    </ProtectRoute>
                } />

                <Route path="masters-jobCategory" element={
                    <ProtectRoute>
                        <Sidebar>
                            <JobCategory />
                        </Sidebar>
                    </ProtectRoute>


                }
                />

                <Route path="masters-jobRank" element={
                    <ProtectRoute>
                        <Sidebar>
                            <JobRank />
                        </Sidebar>
                    </ProtectRoute>


                }
                />
                <Route path="master-subjects" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CourseSubject />
                        </Sidebar>
                    </ProtectRoute>


                }
                />
                <Route path="master-shiptype" element={
                    <ProtectRoute>
                        <Sidebar>
                            <Shiptype />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-certificates" element={
                    <ProtectRoute>
                        <Sidebar>
                            <Certificates />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="masters-createCertificate" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CreateCertificate />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-otherQuestions" element={
                    <ProtectRoute>
                        <Sidebar>
                            <OtherQuestions />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-uploadContent" element={
                    <ProtectRoute>
                        <Sidebar>
                            <UploadContent />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-newsAnnouncement" element={
                    <ProtectRoute>
                        <Sidebar>
                            <NewsAndAnnouncement />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-questions" element={
                    <ProtectRoute>
                        <Sidebar>
                            <AddQuestions />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-ads" element={
                    <ProtectRoute>
                        <Sidebar>
                            <Advertisement />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-course" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CourseBatch />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-jobpost" element={
                    <ProtectRoute>
                        <Sidebar>
                            <JobPost />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-report" element={
                    <ProtectRoute>
                        <Sidebar>
                            <AdminReport />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                {/* Institute Routes */}

                <Route path="institute" element={
                    <ProtectRoute>
                        <Sidebar>
                            <InstituteDashboard />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="institute-courses" element={
                    <ProtectRoute>
                        <Sidebar>
                            <AvailableCourses />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="institute-details" element={
                    <ProtectRoute>
                        <Sidebar>
                            <InstituteDetails />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="institute-course-schedule" element={
                    <ProtectRoute>
                        <Sidebar>
                            <ScheduleCourse />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="institute-reports" element={
                    <ProtectRoute>
                        <Sidebar>
                            <Reports />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="institute-courseRequest" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CourseRequest/>
                        </Sidebar>
                    </ProtectRoute>
                }/>


                {/* Company Routes */}

                <Route path="company/dashboard" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CompanyDashBoard />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="company/job-management/jobpost" element={
                    <ProtectRoute>
                        <Sidebar>
                            <JobpostCompany />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="company/details" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CompanyDetails />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="company/job-management/viewesume" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CandidateResume />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="master-department" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CompanyDepartment/>
                        </Sidebar>
                    </ProtectRoute>
                }
                ></Route>

                <Route path="company/otherinfo" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CompanyOtherInfo />
                        </Sidebar>
                    </ProtectRoute>
                }
                />

                <Route path="company/reports" element={
                    <ProtectRoute>
                        <Sidebar>
                            <CompanyReport />
                        </Sidebar>
                    </ProtectRoute>
                }
                />




                <Route path="*" element={

                    <PageNotFound />

                } />

            </Routes>
            </Suspense>
        </BrowserRouter>
    )
}

export default AppRoutes;