import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {commonslice}  from '../actions/commonSlice';
import storage from "redux-persist/lib/storage";
import {
    persistStore,
    persistReducer,
    FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER
} from 'redux-persist';
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import { adminSlice } from '../actions/AdminSlices';

const persistConfig = {
    key:'root',
    storage,
    stateReconciler:autoMergeLevel2,
    whitelist:['common']
}

const cReducers = combineReducers({
    commonslice:commonslice.reducer,
    adminSlice:adminSlice.reducer
})

const pReducer = persistReducer(persistConfig,cReducers)

export const store = configureStore({
    reducer:pReducer,
    middleware:(getDefaultMiddleware)=>
        getDefaultMiddleware({
            serializableCheck:{
                ignoredActions: [ FLUSH, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER ],
                ignoredPaths: []
            }
        })
    
})

export const persiststore = persistStore(store);