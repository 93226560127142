import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    learningCourse:[],
    text:'',
    news:'',
    addvertisement:'',
}

export const adminSlice = createSlice({
    name:'AdminSlice',
    initialState,
    reducers:{
        setLearningCourses:(state,action)=>{
            state.learningCourse = action.payload
        },
        setText:(state,action)=>{
            state.text = action.payload;
        },
        setNews:(state,action)=>{
            state.news = action.payload;
        },
        setAddvertisement:(state,action)=>{
            state.addvertisement = action.payload
        }
    }
})

export const {setLearningCourses,setText,setNews,setAddvertisement} = adminSlice.actions;
export default adminSlice.reducer;