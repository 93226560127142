import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'animate.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-modal/styles.css';
import 'react-responsive-pagination/themes/classic.css';
import 'react-quill/dist/quill.snow.css';
import 'katex/dist/katex.min.css';
import "./css/bootstrap.css";
import "./StyleHub/main.css";
// import 'react-tooltip/dist/react-tooltip.css';

import App from './App';
import { Provider } from 'react-redux';
import { persiststore, store } from './store/store/store';
import { PersistGate } from 'redux-persist/integration/react';
import 'reactjs-popup/dist/index.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ToastContainer } from 'react-toastify';

   const theme = createTheme(); // Customize if needed

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
          <PersistGate persistor={persiststore}>
            <App />
          </PersistGate>
        </Provider>
        <ToastContainer position='top-center' style={{
                maxHeight: "50px",
            }} />
   </ThemeProvider>
);


