// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderScreen{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
    position: absolute;
    background-color: #fff;
}
.loaderScreen img{
   max-width: 50%;
   object-fit: contain;
}
/* @media only screen and (max-width:767px){
    .loaderScreen img{
        width: 100%;
        height: 50%;
    }
}

@media only screen and (min-width:768px){
    .loaderScreen img{
        width: 100%;
        height: 70%;
    }
}

@media only screen and (min-width:1024px){
    .loaderScreen img{
        width: 90%;
        height: 90%;
    }
}  */`, "",{"version":3,"sources":["webpack://./src/common/loader/loader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,mCAA2B;YAA3B,2BAA2B;IAC3B,kBAAkB;IAClB,sBAAsB;AAC1B;AACA;GACG,cAAc;GACd,mBAAmB;AACtB;AACA;;;;;;;;;;;;;;;;;;;IAmBI","sourcesContent":[".loaderScreen{\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: transparent;\n    backdrop-filter: blur(10px);\n    position: absolute;\n    background-color: #fff;\n}\n.loaderScreen img{\n   max-width: 50%;\n   object-fit: contain;\n}\n/* @media only screen and (max-width:767px){\n    .loaderScreen img{\n        width: 100%;\n        height: 50%;\n    }\n}\n\n@media only screen and (min-width:768px){\n    .loaderScreen img{\n        width: 100%;\n        height: 70%;\n    }\n}\n\n@media only screen and (min-width:1024px){\n    .loaderScreen img{\n        width: 90%;\n        height: 90%;\n    }\n}  */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
